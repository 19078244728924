﻿function newID() {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

//function setCookie(c_name, value, exdays) {
//    var exdate = new Date();
//    exdate.setDate(exdate.getDate() + exdays);
//    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString() + "; path=/");
//    document.cookie = c_name + "=" + c_value;
//}

//function getCookie(c_name) {
//    var i, x, y, ARRcookies = document.cookie.split(";");
//    for (i = 0; i < ARRcookies.length; i++) {
//        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
//        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
//        x = x.replace(/^\s+|\s+$/g, "");
//        if (x == c_name) {
//            return unescape(y);
//        }
//    }
//}

function cfm2m3(cfm) {
    return Math.round(cfm * 1.699011);
}

function m32cfm(m3) {
    return Math.round(m3 / 1.699011);
}

function mm2in(mm) {
    return Math.round(mm / 25.4);

}
function in2mm(inc) {
    return Math.round(inc * 25.4);
}

function ft2m(ft) {
    return Math.round(ft * 0.3048);
}

function m2ft(ft) {
    return Math.round(ft / 0.3048);
}

function in2pa(inc) {
    return Math.round(inc * 249.089);
}

function pa2in(pa) {
    return Math.round(pa / 249.089 * 100)/100;
}

function kw2mb(kw) {
    return Math.round(kw * 3.412141);
}

function mb2kw(mb) {
    return Math.round(mb / 3.412141);
}

function lbh2kgs(lbh) {
    return Math.round(lbh * 0.0001259979 *1000) / 1000;
}

function fpm2ms(fpm) {
    return Math.round(fpm * 0.00508 * 100) / 100;
}

function c2f(c) {
    return Math.round(c*(9/5)+32);
}

function f2c(f) {
    return Math.round((f-32)/(9/5));
}

function getUnits(type) {
    if (ResUnits == "SI") {
        if (type == "Pres") {
            return "Pa"
        }
        if (type == "Vol") {
            return "m3/h"
        }
        if (type == "Vel") {
            return "m/s"
        }
        if (type == "Temp") {
            return "°C"
        }
        if (type == "Mass") {
            return "kg/s"
        }
        if (type == "Inp") {
            return "kW"
        }
        if (type == "Len") {
            return "m"
        }
        if (type == "Dia") {
            return "mm"
        }
    } else {
        if (type == "Pres") {
            return "inWC"
        }
        if (type == "Vol") {
            return "CFM"
        }
        if (type == "Vel") {
            return "fpm"
        }
        if (type == "Temp") {
            return "°F"
        }
        if (type == "Mass") {
            return "lb/h"
        }
        if (type == "Inp") {
            return "MBTU"
        }
        if (type == "Len") {
            return "ft."
        }
        if (type == "Dia") {
            return "in"
        }
    }

}

function getDimText(v,typ) {
    var ret = "";
    var len = Math.sqrt(v.DimX * v.DimX + v.DimY * v.DimY + v.DimZ * v.DimZ);
    if (len == 0) { return "" };
    if (typ == "IP") {
        var rem = len;
        var ft = Math.floor(rem / 12);
        rem -= ft * 12;
        var inc = Math.floor(rem)
        rem -= inc
        var number = (Math.round(rem * 8) / 8).toFixed(3);
        switch (number) {
            case "0.125":
                ret = "⅛";
                break;
            case "0.250":
                ret = "¼";
                break;
            case "0.375":
                ret = "⅜";
                break;
            case "0.500":
                ret = "½";
                break;
            case "0.625":
                ret = "⅝";
                break;
            case "0.750":
                ret = "¾";
                break;
            case "0.875":
                ret = "⅞";
                break;

            default:
                ret = "";
        }

        var res = "";
        if (ft > 0) { res = ft + "'" };
        if (inc > 0) { res += " " + inc };
        if (ret != "") { res += " " + ret }
        if (inc > 0 || ret != "") { res += '"' };
        return res;
    } else {
        return len.toFixed(3) + "m";
    }
    
}

function hasWebGL() {

    //return false;
    var testcan = document.createElement('canvas')
    var contextNames = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"];
    var hasit = false;
    for (var i = 0; i < contextNames.length; i++) {
        try {
            gl = testcan.getContext(contextNames[i]);
            if (gl) {
                hasit = true;
                break;
            }
        } catch (e) {
            hasit = false;

        }

    }
    return hasit;
};

